import React from "react"

const WhatYouGetCards = ({ IconComponent, heading, body }) => {
  return (
    <div className="col-sm-6 col-md-4 mb-4">
      <div className="row no-gutters">
        <div className="col-2 col-sm-3 col-lg-2 text-right pr-2">
          <IconComponent style={{ width: "32px", height: "32px" }} />
        </div>
        <div className="col-10 col-sm-9 col-lg-10">
          <h6 className="text-uppercase">{heading}</h6>
          <div className="text-muted mb-3">{body}</div>
        </div>
      </div>
    </div>
  )
}

export default WhatYouGetCards
