import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const WebCaseStudies = () => {
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  }
  return (
    <StaticQuery
      query={graphql`
        {
          casestudy1: file(
            relativePath: { eq: "work/mobile/foodel/1-landing-page.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 900, layout: CONSTRAINED)
            }
          }
          casestudy2: file(
            relativePath: { eq: "work/mobile/amori/login-wide.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 900, layout: CONSTRAINED)
            }
          }
          casestudy3: file(
            relativePath: {
              eq: "work/mobile/wallet-optimizer/splash-screen-wide.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 900, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={(data) => (
        <section id="MobileCaseStudies" className="pt-5 my-5">
          <div className="container">
            <div className="row mb-4 pb-3">
              <div className="col-lg-9 m-auto text-center">
                <h2 className="text-capitalize">Case Studies</h2>
                <p className=" text-muted fw-l">
                  Take a look at some of our recent mobile development projects.
                </p>
              </div>
            </div>
          </div>
          <div className="container mb-5 pb-5 px-5">
            <Slider {...slickSettings}>
              <Link
                to="/work/mobile-applications/foodel"
                className="position-relative"
              >
                <GatsbyImage
                  image={data.casestudy1.childImageSharp.gatsbyImageData}
                  alt="Mobile development case study 1"
                  style={{
                    borderRadius: "7px",
                  }}
                />
                <div
                  className="light-overlay"
                  style={{
                    borderRadius: "7px",
                  }}
                ></div>
                <div className="case-study-overlay">
                  <h3 className="heading mb-0 text-white">Foodel</h3>
                  <p className="description text-white text-center">
                    Foodel is a healthy meal subscription delivery service in
                    the Jacksonville area.
                  </p>
                </div>
              </Link>

              <Link
                to="/work/mobile-applications/amori"
                className="position-relative"
              >
                <GatsbyImage
                  image={data.casestudy2.childImageSharp.gatsbyImageData}
                  alt="Mobile development case study 2"
                  style={{
                    borderRadius: "7px",
                  }}
                />
                <div
                  className="light-overlay"
                  style={{
                    borderRadius: "7px",
                  }}
                ></div>
                <div className="case-study-overlay">
                  <h3 className="heading mb-0 text-white">Amori</h3>
                  <p className="description text-white text-center">
                    Amori is a dating application that caters to all genders and
                    sexual preferences based out of NY.
                  </p>
                </div>
              </Link>

              <Link
                to="/work/mobile-applications/wallet-optimizer"
                className="position-relative"
              >
                <GatsbyImage
                  image={data.casestudy3.childImageSharp.gatsbyImageData}
                  alt="Mobile development case study 3"
                  style={{
                    borderRadius: "7px",
                  }}
                />
                <div
                  className="light-overlay"
                  style={{
                    borderRadius: "7px",
                  }}
                ></div>
                <div className="case-study-overlay">
                  <h3 className="heading mb-0 text-white">Wallet Optimizer</h3>
                  <p className="description text-white text-center">
                    Wallet Optimizer helps people manage their finances through
                    one convenient mobile application.
                  </p>
                </div>
              </Link>
            </Slider>
          </div>
        </section>
      )}
    />
  )
}

export default WebCaseStudies
