import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import RightIcon from "../../../../assets/icons/chevron-right.svg"

const AgileApproach = () => (
  <StaticQuery
    query={graphql`
      {
        agileApproach: file(relativePath: { eq: "kanban.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 900, layout: CONSTRAINED)
          }
        }
      }
    `}
    render={(data) => (
      <section id="AgileApproach" className="my-5">
        <div className="container">
          <div className="row position-relative">
            <div className="square-bg" />
            <div className="col-lg-6">
              <div className="pb-4 d-lg-none">
                <h6 className="text-uppercase pb-2 text-muted">Our Approach</h6>
                <GatsbyImage
                  image={data.agileApproach.childImageSharp.gatsbyImageData}
                  alt="Mobile Development Approach"
                  style={{
                    padding: "10px",
                    border: "10px solid #fff",
                    borderRadius: "5px",
                    boxShadow: "0px 5px 10px 0px rgba(113, 62, 90, 0.1)",
                  }}
                />
              </div>
              <h6 className="text-uppercase d-none d-md-block text-muted">
                Our Approach
              </h6>
              <p className="h2 text-gr fw-l">
                We use{" "}
                <span className="text-primary">Agile Project Management</span>{" "}
                so you're always in the driver's seat.
              </p>
              <p className="text-muted">
                Using Agile principles, we are able to deliver working software
                features every 1-2 weeks. This is also known as an "iteration"
                or "sprint". We get feedback from you on every iteration which
                we continually integrate to make a fully fledged application.
                Agile Project Management ultimately brings your exact vision to
                life.
              </p>
              <p className="text-muted">
                We created a detailed description of how we approach application
                development. Please follow the link below if you want an in
                depth look on how your mobile application will be built.
              </p>
              <Link to="/approach/" className="btn btn-info">
                <span>Learn More </span>
                <RightIcon />
              </Link>
            </div>
            <div className="col-lg-6 d-none d-lg-block">
              <GatsbyImage
                image={data.agileApproach.childImageSharp.gatsbyImageData}
                alt="Mobile Development Approach"
                style={{
                  padding: "10px",
                  border: "10px solid #fff",
                  borderRadius: "5px",
                  boxShadow: "0px 5px 10px 0px rgba(113, 62, 90, 0.1)",
                }}
              />
            </div>
          </div>
        </div>
      </section>
    )}
  />
)

export default AgileApproach
