import React from "react"
import WhatYouGetCards from "./MobileWhatYouGetCards"

import ConsultIcon from "../../../../assets/icons/blackboard.svg"
import WorkshopIcon from "../../../../assets/icons/open-book.svg"
import ProjectPlanIcon from "../../../../assets/icons/text-document.svg"
import DeliveryIcon from "../../../../assets/icons/rocket.svg"
import ReleaseIcon from "../../../../assets/icons/flag.svg"
import SupportIcon from "../../../../assets/icons/headset_mic.svg"

const WhatYouGet = () => {
  const cardDetails = {
    card1: {
      icon: ConsultIcon,
      heading: "Consultation",
      body: (
        <>
          This is where we discuss your business objectives, target market and
          potential competition as well as whether your time and budget align
          with your vision. We consult on:
          <ul className="ml-2 pl-3 mt-3 fa-ul">
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              Business Objectives
            </li>
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              Target Market
            </li>
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              Time and Budget
            </li>
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              and{" "}
              <a href="/approach/" target="_blank" className="btn-inline">
                much more...
              </a>
            </li>
          </ul>
        </>
      ),
    },
    card2: {
      icon: WorkshopIcon,
      heading: "Story Workshop",
      body: (
        <>
          Using{" "}
          <a href="/approach/" target="_blank" className="btn-inline">
            Agile methodologies
          </a>
          , we work with you to collect all the feature requirements (AKA
          stories) for your application. We help you build stories that are:
          <ul className="ml-2 pl-3 mt-3 fa-ul">
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              Independent
            </li>
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              Negotiable
            </li>
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              Testable
            </li>
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              Estimateable
            </li>
          </ul>
        </>
      ),
    },
    card3: {
      icon: ProjectPlanIcon,
      heading: "Project Plan",
      body: (
        <>
          We provide you with a clearly defined project plan that we build using
          the features we collected and prioritized from the Story Workshop. The
          project plan includes:
          <ul className="ml-2 pl-3 mt-3 fa-ul">
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              Build Schedule
            </li>
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              Deadlines
            </li>
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              Budget
            </li>
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              Payment Schedule
            </li>
          </ul>
        </>
      ),
    },
    card4: {
      icon: DeliveryIcon,
      heading: "Bi-Weekly Delivery",
      body: (
        <>
          We provide working software features every 1-2 weeks. These working
          features are collectively known as "iterations" and shows tangible
          progress throughout your project. Iterations are:
          <ul className="ml-2 pl-3 mt-3 fa-ul">
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              Integrateable
            </li>
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              Tested
            </li>
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              Fully Functional
            </li>
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              Ready to Deploy
            </li>
          </ul>
        </>
      ),
    },
    card5: {
      icon: ReleaseIcon,
      heading: "First Release",
      body: (
        <>
          Even though application development is an ongoing process, the first
          release is the first version of your product that's usable by your
          customers. You will receive:
          <ul className="ml-2 pl-3 mt-3 fa-ul">
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              A Well Tested Application{" "}
            </li>
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              Fully Functional Application Ready For Customer Interaction
            </li>
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              Maintainable and Scalable code
            </li>
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              After Launch Support
            </li>
          </ul>
        </>
      ),
    },
    card6: {
      icon: SupportIcon,
      heading: "Professional Support",
      body: (
        <>
          It's crucial to get your questions answered as soon as possible. You
          will get support throughout the lifecycle of your project as well as
          after launch. Support includes:
          <ul className="ml-2 pl-3 mt-3 fa-ul">
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              Bi-Weekly Progress Meetings
            </li>
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              24 Hour Response Time
            </li>
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              Knowledgeable Staff
            </li>
            <li>
              <span className="fa-li text-success">
                <i className="fas fa-check-circle"></i>
              </span>
              Emergency Support For The First Week of Launch
            </li>
          </ul>
        </>
      ),
    },
  }
  let cards = []
  for (let key in cardDetails) {
    cards.push(
      <WhatYouGetCards
        key={key}
        IconComponent={cardDetails[key].icon}
        heading={cardDetails[key].heading}
        body={cardDetails[key].body}
      />
    )
  }
  return (
    <section id="WebDevelopment" className="pt-5 my-5">
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-9 m-auto text-center">
            <h2 className="text-capitalize">What you get</h2>
            <p className=" text-muted fw-l">
              We don't simply create a mobile application and send you on your
              way. Below are 6 of the most important deliverables you'll receive
              during your project:
            </p>
          </div>
        </div>
        <div className="row">{cards}</div>
      </div>
    </section>
  )
}

export default WhatYouGet
