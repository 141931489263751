import React from "react"
import NumBoxes from "../../../common/NumBoxes"
import Description from "../mobile-development/MobileWhatItCostsDescription"

import DeveloperIcon from "../../../../assets/icons/users.svg"
import CostIcon from "../../../../assets/icons/credit.svg"
import CalendarIcon from "../../../../assets/icons/calendar.svg"

const WhatItCosts = () => {
  const numBoxDetails = {
    box1: {
      icon: DeveloperIcon,
      number: "1+",
      heading: "Developers",
    },
    box2: {
      icon: CostIcon,
      number: "65+",
      heading: "Hourly",
    },
    box3: {
      icon: CalendarIcon,
      number: "1+",
      heading: "Months",
    },
  }
  return (
    <section id="WhatItCosts" className="my-5">
      <div className="container">
        <div className="row mb-4">
          <div className="col-lg-9 m-auto text-center">
            <h2 className="text-capitalize">Time and Budget</h2>
            <p className=" text-muted fw-l">
              Every application is distinctly unique in its design, features,
              target market and overall objectives. Here are the{" "}
              <span className="text-primary">minimum numbers</span> we see on
              our projects:
            </p>
          </div>
        </div>
        <NumBoxes details={numBoxDetails} />
        <Description />
      </div>
    </section>
  )
}

export default WhatItCosts
