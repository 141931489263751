import React from "react"

import TechnologyIcons from "./MobileTechnologyIcons"

import HTML5Icon from "../../../../assets/icons/html5.svg"
import TailwindCSSIcon from "../../../../assets/icons/tailwindcss.svg"
import TypescriptIcon from "../../../../assets/icons/typescript.svg"
import ReactIcon from "../../../../assets/icons/reactIcon.svg"
import JestIcon from "../../../../assets/icons/jest.svg"
import NodeIcon from "../../../../assets/icons/node-js.svg"
import GraphQLIcon from "../../../../assets/icons/graphql.svg"
import MongoDBIcon from "../../../../assets/icons/mongodb.svg"
import GitIcon from "../../../../assets/icons/git.svg"
import CircleCIIcon from "../../../../assets/icons/circleci-icon.svg"
import DockerIcon from "../../../../assets/icons/docker.svg"
import AWSIcon from "../../../../assets/icons/amazonaws.svg"
import AppStoreIcon from "../../../../assets/icons/apple.svg"
import GooglePlayIcon from "../../../../assets/icons/googleplay.svg"

const MobileTechnologies = () => {
  const brandIcons = {
    HTML5: {
      icon: HTML5Icon,
      name: "HTML5",
    },
    TailwindCSS: {
      icon: TailwindCSSIcon,
      name: "TailwindCSS",
    },
    Typescript: {
      icon: TypescriptIcon,
      name: "Typescript",
    },
    React: {
      icon: ReactIcon,
      name: "React Native",
    },
    Jest: {
      icon: JestIcon,
      name: "Jest",
    },
    Node: {
      icon: NodeIcon,
      name: "Node.js",
    },
    GraphQL: {
      icon: GraphQLIcon,
      name: "GraphQL",
    },
    MongoDB: {
      icon: MongoDBIcon,
      name: "MongoDB",
    },
    GIT: {
      icon: GitIcon,
      name: "Git",
    },
    Docker: {
      icon: DockerIcon,
      name: "Docker",
    },
    CircleCI: {
      icon: CircleCIIcon,
      name: "CircleCI",
    },
    AWS: {
      icon: AWSIcon,
      name: "AWS",
    },
    AppStore: {
      icon: AppStoreIcon,
      name: "App Store",
    },
    GooglePlay: {
      icon: GooglePlayIcon,
      name: "Google Play",
    },
  }

  return (
    <section id="mobiletechnologies" className="pt-5 my-5">
      <div className="container">
        <div className="row mb-4">
          <div className="col-lg-9 m-auto text-center">
            <h2 className="text-capitalize">Technologies we use</h2>
            <p className="text-muted fw-l">
              We use the latest technologies to build you a modern, secure, and
              scalable mobile application. Here is a list of the technologies we
              use:
            </p>
          </div>
        </div>
        <TechnologyIcons iconObject={brandIcons} />
      </div>
    </section>
  )
}

export default MobileTechnologies
