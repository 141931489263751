import React from "react"

import TouchIcon from "../../../../assets/icons/touch_app.svg"
import ToggleIcon from "../../../../assets/icons/toggle-on.svg"
import MarketingIcon from "../../../../assets/icons/megaphone.svg"

const MobileDevelopment = () => (
  <section id="MobileDevelopment" className="my-5">
    <div className="container">
      <div className="row mb-4">
        <div className="col-lg-9 m-auto text-center">
          <h2 className="text-capitalize">Why build a mobile application?</h2>
          <p className=" text-muted fw-l">
            Mobile applications live in the hands of your end users and allow
            for unparalleled interactivity. Here are 3 reasons why you should
            consider building a mobile app:
          </p>
        </div>
      </div>
      <div className="row text-center">
        <div className="col-md-4 mb-5">
          <TouchIcon className="pb-2" />
          <h5 className="text-uppercase">User Experience</h5>
          <p className="text-muted fw-l">
            Give your end users a rich, tailored user experience by utilizing
            the unique touch, location, and camera resources available on
            smartphones and tablets.
          </p>
        </div>
        <div className="col-md-4 mb-5">
          <ToggleIcon className="pb-2" />
          <h5 className="text-uppercase">Easy Access</h5>
          <p className="text-muted fw-l">
            With no web browser or URL memorization required, mobile
            applications are simply downloaded to the user's device allowing for
            both online and offline usage.
          </p>
        </div>
        <div className="col-md-4">
          <MarketingIcon className="pb-2" />
          <h5 className="text-uppercase">Marketing Potential</h5>
          <p className="text-muted fw-l">
            Get instant access to millions of smartphone users through platform
            specific app stores as well as marketing to existing users through
            push notifications.
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default MobileDevelopment
