import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PageHeader from "../../components/common/PageHeader"
import MobileDevelopment from "../../components/pages/services/mobile-development/MobileDevelopment"
import MobileAgileApproach from "../../components/pages/services/mobile-development/MobileAgileApproach"
import MobileWhatYouGet from "../../components/pages/services/mobile-development/MobileWhatYouGet"
import MobileWhatItCosts from "../../components/pages/services/mobile-development/MobileWhatItCosts"
import MobileTechnologies from "../../components/pages/services/mobile-development/MobileTechnologies"
import MobileCaseStudies from "../../components/pages/services/mobile-development/MobileCaseStudies"
import Contact from "../../components/pages/index/Contact"

const MobileDevelopmentPage = () => (
  <StaticQuery
    query={graphql`
      {
        mobileDevelopment: file(
          relativePath: { eq: "mobile-development.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `}
    render={(data) => (
      <Layout>
        <Seo
          title="Mobile Development"
          keywords={[`mobile development`]}
          description="Get your app onto the most popular smartphones and tablets. We develop full-stack iOS and Android applications with an intense focus on user experience."
        />
        <PageHeader
          image={data.mobileDevelopment.childImageSharp.gatsbyImageData}
          title="Mobile Development"
          subTitle="Mobile applications with unlimited potential"
          overlay="darker"
          formLocation="onPage"
        />
        <main>
          <MobileDevelopment />
          <MobileAgileApproach />
          <MobileTechnologies />
          <MobileWhatYouGet />
          <MobileWhatItCosts />
          <MobileCaseStudies />
          <Contact
            heading={
              <>
                Ready to build a custom{" "}
                <span className="text-primary">mobile</span> application?
              </>
            }
          />
        </main>
      </Layout>
    )}
  />
)

export default MobileDevelopmentPage
