import React from "react"

const TechnologyIcons = ({ iconObject }) => {
  let icons = []
  for (let key in iconObject) {
    let IconComponent = iconObject[key].icon
    icons.push(
      <div key={key} className="d-inline-block">
        <div className="d-flex align-items-center pr-4 mb-3">
          <IconComponent
            className="mr-1"
            style={{
              width: "25px",
              height: "25px",
              fill: "#f51465",
            }}
          />{" "}
          <h6 className="text-muted mb-0">{iconObject[key].name}</h6>
        </div>
      </div>
    )
  }
  return (
    <div className="row justify-content-center">
      <div className="col-lg-9 m-auto text-center">{icons}</div>
    </div>
  )
}

export default TechnologyIcons
